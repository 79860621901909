<template>
  <div class="access-permission-container">
    <vs-row class="mt-5">
      <vs-card class="fix-pads">
        <div slot="header">
          <div class="flex justify-between items-center">
            <h3 class="font-medium">Users</h3>
          </div>
        </div>
        <div class="flex justify-end">
          <vs-button
              class="only-border-btn"
              size="medium"
              v-round
              @click="$router.push({ name: 'partner-users-create' })"
              >
              <plus-icon size="1.5x" class="button-icon"></plus-icon>
              New User</vs-button
            >
        </div>
        <vs-table
          :max-items="descriptionItems[0]"
          pagination
          :data="users"
          class="assign-users-table search-left stripes"
        >
          <template slot="thead">
            <vs-th> Name </vs-th>
            <vs-th> Role </vs-th>
            <vs-th> Email </vs-th>
            <vs-th> Phone Number </vs-th>
            <vs-th v-if="merchantGroups.length > 0"> Status </vs-th>
            <vs-th> </vs-th>
            <vs-th> </vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :key="indextr" v-for="(user, indextr) in data">
              <vs-td :data="user.title">
                {{ user.userType ==="introducerUser" && user.introducer ? (user.introducer.platformName || user.introducer.name) :(user.fullName ? user.fullName : user.systemName) }}
              </vs-td>
              <vs-td :data="user.userType">
                <span v-if="user.userType == 'admin'">Super Admin</span>
                <span v-if="user.userType == 'staffAdmin'">Admin</span>
                <span v-if="user.userType == 'apiUser'">API User</span>
                <span v-if="user.userType == 'staff'">Staff</span>
                <span v-if="user.userType == 'introducerUser'">Introducer User</span>
              </vs-td>
              <vs-td :data="user.reference">
                {{ user.email ? user.email : "N/A" }}
              </vs-td>
              <vs-td :data="user.companyName">
                {{ user.phoneNumber ? user.phoneNumber : "N/A" }}
              </vs-td>

              <vs-td v-if="merchantGroups.length > 0" :data="user.groupAccess">
                <span :class="changeStatusColor(user.groupAccess.length > 0 ? 'Assigned' : 'Not assigned')">
                {{ user.groupAccess.length > 0 ? "Assigned" : "Not assigned" }}
                </span>
              </vs-td>

              <vs-td :data="user">
                <a
                  v-if="user.userType != 'apiUser' && user.partnerId && user.userType != 'introducerUser'"
                  @click="sendCredentials(user._id)"
                  ><u>{{
                    user.credentialsSent
                      ? "Resend credentials"
                      : "Send credentials"
                  }}</u></a
                >
              </vs-td>

              <vs-td :data="user._id">
                <a v-if="user.userType != 'introducerUser'"
                @click="editUser(user._id)"><u>Edit</u></a>
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </vs-card>
    </vs-row>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { EditIcon, PlusIcon } from "vue-feather-icons";

export default {
  components: {
    EditIcon,PlusIcon
  },
  data() {
    return {
      descriptionItems: [20, 25, 40],
      users: [],
      merchantGroups: [],
      apiMerchantGroups: [],
    };
  },
  methods: {
    ...mapActions("partner", [
      "fetchStaffByPartnerId",
      "sendCredentialsToStaff",
    ]),
    ...mapActions("merchantGroup", [
        "fetchMerchantGroupByMerchantId",
      ]),

    async sendCredentials(id) {
      await this.sendCredentialsToStaff(id)
        .then((result) => {
          this.$vs.notify({
          title: "Success",
          text: "The username and password has been successfully sent!",
          color: "success",
          iconPack: "feather",
          position: "top-right",
          icon: "icon-check-circle",
        });
          this.getUsers(this.partnerId)
        })
        .catch((err) => {});
    },

    // functions of users
    async getUsers(partnerId) {
      await this.fetchStaffByPartnerId(partnerId)
        .then((result) => {
          this.users = result.data.data;
        })
        .catch((err) => {
          
          if(err.status == 400){
            this.$vs.notify({
              title: 'Staff',
              text: err.data.message,
              color: 'danger',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              position: "top-right",
            })
          }
        });
    },
    editUser(id) {
      this.$router.push({
        name: "partner-users-edit",
        params: { id: id },
      });
    },
    changeStatusColor(status) {
      let bgClass = "";
      if (status == "Assigned") {
        bgClass = "badge success";
      } else if (status == "Not assigned") {
        // yellow
        bgClass = "badge warning";
      }
      return bgClass;
    },
    async getMerchantGroups(partnerId) {
      await this.fetchMerchantGroupByMerchantId(partnerId)
        .then((res) => {
          this.merchantGroups = res.data.data.docs;
        })
        .catch((err) => {});
    },
  },

  mounted() {
    this.getUsers(this.partnerId);
    this.getMerchantGroups(this.partnerId);
  },

  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
     partnerId() {
      return this.user.userType === "admin"
        ? this.user._id
        : this.user.partnerId
    },
  },
};
</script>